import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid"; // Assuming you're using DataGrid from Material-UI
import axios from "axios";
import { api } from "../../Api";
const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

export const getPackageDataApi = async () => {
  try {
    const res = await axios.get(api + "/newskill/getPackages");
    const formattedData = res.data.packgedata.map((row, index) => ({
      ...row,
      id: index + 1,
      effective_date: formatDate(row.effective_date),
      expiration_date: formatDate(row.expiration_date),
    }));
    return formattedData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

const PackageList = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getPackageDataApi().then((formattedData) => setData(formattedData));
  }, []);

  const columns = [
    // Define columns for your table
    { field: "id", headerName: "ID", width: 70 },
    { field: "package_name", headerName: "Name", width: 100 },
    { field: "amount", headerName: "Amount", width: 70 },
    { field: "package_type", headerName: "Type", width: 70 },
    { field: "package_for", headerName: "For", width: 70 },
    { field: "validity", headerName: "Validity", width: 70 },
    { field: "no_of_internships", headerName: "No.Of Internships", width: 70 },
    { field: "no_of_dept", headerName: "No.Of Department", width: 70 },
    { field: "effective_date", headerName: "Effective Date", width: 150 },
    { field: "expiration_date", headerName: "Expiration Date", width: 150 },
    { field: "package_desc", headerName: "Package Description", width: 70 },
    { field: "", headerName: "Status", width: 70 },
    // Add more columns as needed
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 25]}
        pagination
        getRowId={(row) => row.id} // Specify the id property for each row
      />
    </div>
  );
};

export default PackageList;

import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import axios from "axios";
import { api } from "../../Api";
import { useEffect, useState } from "react";

function AllStudentList() {
  const [students, setStudents] = useState([]);

  const getStd = async () => {
    try {
      const res = await axios.get(api + "/newskill/getAllStudent");
      console.log("Student data: ", res.data);
      setStudents(res.data); // Update the state with the received students data
    } catch (error) {
      console.error("Error fetching students: ", error);
    }
  };

  useEffect(() => {
    getStd();
  }, []); // Fetch data on component mount

  // Define the columns for DataGrid
  const columns = [
    { field: "studentId", headerName: "S-ID", width: 100 },
    { field: "studentName", headerName: "Name", width: 200 },
    { field: "emailId", headerName: "Email", width: 200 },
    { field: "contact", headerName: "Contact", width: 200 },
    { field: "collegeName", headerName: "College", width: 150 },
    { field: "departmentName", headerName: "Department", width: 150 },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {students.length > 0 ? (
              <div style={{ height: 400, width: "100%" }}>
                <DataGrid
                  rows={students}
                  columns={columns}
                  pageSize={5}
                  getRowId={(row) => row.studentId} // Use studentId as the unique key for rows
                  checkboxSelection
                />
              </div>
            ) : (
              <div>No students found</div>
            )}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AllStudentList;

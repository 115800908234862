import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import axios from "axios";
import { api } from "../../Api";
import { useEffect, useState } from "react";

function AllAdvertismentList() {
  const [adv, setAdv] = useState([]);

  const getStd = async () => {
    try {
      const token = localStorage.getItem("Admintoken"); // Adjust the key name as needed
      const config = {
        headers: {
          Authorization: `Bearer ${token}`, // Include token in Authorization header
        },
      };

      const res = await axios.get(api + "/newskill/api/advertisement", config);
      console.log("Advertisement data: ", res.data);
      setAdv(res.data.advertisement); // Update the state with the received advertisement data
    } catch (error) {
      console.error("Error fetching advertisements: ", error);
    }
  };

  useEffect(() => {
    getStd();
  }, []); // Fetch data on component mount

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "companyName", headerName: "Company Name", width: 200 },
    { field: "technology", headerName: "Technology", width: 150 },
    { field: "creation_date", headerName: "Creation Date", width: 150 },
    {
      field: "expiration_date",
      headerName: "Expiration Date",
      width: 150,
      valueFormatter: (params) => {
        // Improved error handling
        if (params && params.value) {
          return params.value;
        }
        return "N/A"; // Handle null or undefined values
      },
    },
    { field: "status", headerName: "Status", width: 100 },
    { field: "adv_title", headerName: "Advertisement Title", width: 200 },
    { field: "location", headerName: "Location", width: 150 },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {adv.length > 0 ? (
              <div style={{ height: 400, width: "100%" }}>
                <DataGrid
                  rows={adv} // Use adv for rows
                  columns={columns}
                  pageSize={5}
                  getRowId={(row) => row.id} // Use id as the unique key for rows
                  checkboxSelection
                />
              </div>
            ) : (
              <div>No advertisements found</div> // Adjust message accordingly
            )}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AllAdvertismentList;

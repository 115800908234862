import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { api } from "../../Api";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

export const getDepartmentDataApi = async () => {
  try {
    const res = await axios.get(api + "/newskill/getDept");
    const formattedData = res.data.DeptList.map((row, index) => ({
      ...row,
      id: row.deptId, // Make sure id maps to deptId
      creationDate: formatDate(row.creationDate),
    }));
    return formattedData;
  } catch (err) {
    console.log("err", err);
    throw err;
  }
};

const DepartmentList = () => {
  const [data, setData] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editedDepartment, setEditedDepartment] = useState(null);

  useEffect(() => {
    getDepartmentDataApi().then((formattedData) => setData(formattedData));
  }, []);

  const handleDelete = async (deptId) => {
    try {
      const resp = await axios.delete(api + `/newskill/deleteDept?deptId=${deptId}`);
      getDepartmentDataApi().then((formattedData) => setData(formattedData));
      alert("Department Deleted Successfully");
    } catch (err) {
      console.log("Error fetching department:", err);
    }
  };

  const handleEdit = (deptId) => {
    const selectedDept = data.find((dept) => dept.id === deptId);
    setEditedDepartment(selectedDept);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setEditedDepartment(null);
    setOpenEditModal(false);
  };

  const handleSaveChanges = async () => {
    try {
      if (!editedDepartment) {
        return;
      }

      const { deptId, dept_name, dept_type } = editedDepartment;
      const payload = { dept_name, dept_type };

      const resp = await axios.put(api + `/newskill/editDept?deptId=${deptId}`, payload);

      getDepartmentDataApi().then((formattedData) => setData(formattedData));
      setOpenEditModal(false);
      alert("Changes saved successfully");
    } catch (err) {
      console.log("Error updating department:", err);
    }
  };

  const handleInputChange = (e) => {
    if (!editedDepartment) {
      return;
    }
    const { name, value } = e.target;
    setEditedDepartment({ ...editedDepartment, [name]: value });
  };

  const columns = [
    { field: "deptId", headerName: "ID", width: 100 },
    { field: "dept_name", headerName: "Department Name", width: 200 },
    { field: "dept_type", headerName: "Department Type", width: 200 },
    { field: "creationDate", headerName: "Creation Date", width: 200 },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => (
        <>
          <Button variant="contained" color="error" onClick={() => handleDelete(params.row.deptId)}>
            Delete
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleEdit(params.row.deptId)}
            style={{ marginLeft: 10 }}
          >
            Edit
          </Button>
        </>
      ),
    },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 25]}
        pagination
        getRowId={(row) => row.id}
      />

      <Dialog open={openEditModal} onClose={handleCloseEditModal}>
        <DialogTitle>Edit Department</DialogTitle>
        <DialogContent>
          <DialogContentText>Edit department details here.</DialogContentText>
          {editedDepartment && (
            <>
              <TextField
                autoFocus
                margin="dense"
                id="id"
                name="id"
                label="ID"
                fullWidth
                value={editedDepartment.id}
                disabled
              />
              <TextField
                margin="dense"
                id="dept_name"
                name="dept_name"
                label="Department Name"
                fullWidth
                value={editedDepartment.dept_name}
                onChange={handleInputChange}
              />
              <FormControl fullWidth margin="dense">
                <InputLabel id="dept-type-label">Department Type</InputLabel>
                <Select
                  labelId="dept-type-label"
                  id="dept_type"
                  name="dept_type"
                  value={editedDepartment.dept_type}
                  onChange={handleInputChange}
                >
                  <MenuItem value="UG">Under Graduate</MenuItem>
                  <MenuItem value="PG">Post Graduate</MenuItem>
                  <MenuItem value="diploma">Diploma</MenuItem>
                  <MenuItem value="phd">PHD</MenuItem>
                </Select>
              </FormControl>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditModal}>Cancel</Button>
          <Button onClick={handleSaveChanges} color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DepartmentList;

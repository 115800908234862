import { CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "layouts/authentication/components/Footer";
import React, { useEffect, useState } from "react";
import axios from "axios"; // Ensure axios is imported
import { api } from "../../Api";
function TraineeTutList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const formattedData = await getOnlineTestDataApi();
        setData(formattedData);
      } catch (err) {
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const getOnlineTestDataApi = async () => {
    try {
      const res = await axios.get(api + "/newskill/getAllTutor");
      console.log("Fetched data:", res.data.tutors);

      const formattedData = res.data.tutors.map((tut) => ({
        id: tut.tutorId,
        name: tut.name || "N/A", // Provide a default value if name is null
        email: tut.email,
        batchName: tut.batches.length > 0 ? tut.batches[0].batchName : "N/A", // Get the first batch name or "N/A" if none
      }));

      return formattedData;
    } catch (err) {
      console.error("Error fetching data:", err);
      throw err;
    }
  };

  const columns = [
    // { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", width: 100 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "batchName", headerName: "Batch Name", width: 150 },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div style={{ height: 400, width: "100%" }}>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <DataGrid
            rows={data}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 25]}
            pagination
            getRowId={(row) => row.id}
          />
        )}
      </div>
    </DashboardLayout>
  );
}

export default TraineeTutList;

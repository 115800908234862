import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Container,
  Box,
  Typography,
  FormControl,
  FormLabel,
  Button,
  TextField,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import axios from "axios";
import { api } from "../../Api";
import Swal from "sweetalert2";
import { DataGrid } from "@mui/x-data-grid";
import AddbannerData from "components/Modal/AddBannerData";

function StudyMaterial() {
  const [formData, setFormData] = useState({
    courseName: "",
    courseDuration: "",
    courseDescription: "",
    courseImageUrl: null,
  });
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null); // State for the selected row ID

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const validateForm = () => {
    let errors = {};
    if (!formData.courseName) errors.courseName = "Course Name is required.";
    if (!formData.courseDuration) errors.courseDuration = "Course duration is required.";
    if (!formData.courseDescription) errors.courseDescription = "Course description is required.";
    if (!formData.courseImageUrl) errors.courseImageUrl = "Image upload is required.";
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append(
      "coursesEntity",
      new Blob(
        [
          JSON.stringify({
            courseName: formData.courseName,
            courseDuration: formData.courseDuration,
            courseDescription: formData.courseDescription,
          }),
        ],
        { type: "application/json" }
      )
    );
    formDataToSend.append("file", formData.courseImageUrl);

    setLoading(true);
    try {
      const response = await axios.post(`${api}/newskill/course`, formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      Swal.fire({ icon: "success", title: "Success!", text: "Form submitted successfully." });
      setFormData({
        courseName: "",
        courseDuration: "",
        courseDescription: "",
        courseImageUrl: null,
      });
      setFormErrors({});
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an error submitting the form.",
      });
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { field: "courseId", headerName: "Course Id", width: 150 },
    { field: "courseName", headerName: "Course Name", width: 200 },
    { field: "courseDuration", headerName: "Course Duration", width: 150 },
    { field: "courseDescription", headerName: "Course Description", width: 200 },
    {
      field: "actions",
      headerName: "Actions",
      width: 350,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="contained"
            color=""
            onClick={() => {
              setSelectedRowId(params.row.id); // Set the selected row ID
              setIsAddModalOpen(true); // Open the modal
            }}
          >
            Add Data
          </Button>
        </div>
      ),
    },
  ];

  const myList = () => {
    getOnlineTestDataApi()
      .then((formattedData) => {
        setData(formattedData);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  useEffect(() => {
    myList();
  }, []);

  const getOnlineTestDataApi = async () => {
    try {
      const res = await axios.get(api + "/newskill/getCourses");
      const formattedData = res.data.courses.map((row) => ({
        id: row.courseId,
        courseId: row.courseId,
        courseName: row.courseName,
        courseDuration: row.courseDuration,
        courseDescription: row.courseDescription,
      }));
      return formattedData;
    } catch (err) {
      console.error("Error fetching data:", err);
      throw err;
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Container maxWidth="sm">
          <Box sx={{ mt: 4 }}>
            <Typography variant="h4" component="h1" gutterBottom>
              Assign Batch To Students
            </Typography>
            <hr />
            <br />
            <form onSubmit={handleSubmit} noValidate>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <FormControl sx={{ minWidth: 200 }}>
                  <FormLabel component="legend">Course Name</FormLabel>
                  <TextField
                    name="courseName"
                    value={formData.courseName}
                    onChange={handleChange}
                    error={Boolean(formErrors.courseName)}
                    placeholder="Enter Course Name"
                    fullWidth
                    helperText={formErrors.courseName}
                  />
                </FormControl>
                <FormControl sx={{ minWidth: 200 }}>
                  <FormLabel component="legend">Course Duration</FormLabel>
                  <TextField
                    name="courseDuration"
                    value={formData.courseDuration}
                    onChange={handleChange}
                    error={Boolean(formErrors.courseDuration)}
                    placeholder="Enter Course Duration"
                    fullWidth
                    helperText={formErrors.courseDuration}
                  />
                </FormControl>
                <FormControl sx={{ minWidth: 200 }}>
                  <FormLabel component="legend">Course Description</FormLabel>
                  <TextField
                    name="courseDescription"
                    value={formData.courseDescription}
                    onChange={handleChange}
                    error={Boolean(formErrors.courseDescription)}
                    placeholder="Course Description"
                    fullWidth
                    helperText={formErrors.courseDescription}
                  />
                </FormControl>

                <Button
                  type="submit"
                  variant="contained"
                  color="success"
                  sx={{ minWidth: 100 }}
                  disabled={loading}
                >
                  Submit
                </Button>
              </Box>
            </form>
          </Box>
        </Container>
      </MDBox>
      <div style={{ height: 400, width: "100%" }}>
        {data.length > 0 ? (
          <DataGrid
            rows={data}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 25]}
            pagination
            getRowId={(row) => row.id}
          />
        ) : (
          <div style={{ display: "block", position: "relative", left: "50%", top: "50%" }}>
            <CircularProgress />
          </div>
        )}
      </div>

      {/* Add the modal here */}
      {isAddModalOpen && (
        <AddbannerData selectedRowId={selectedRowId} handleClose={() => setIsAddModalOpen(false)} />
      )}
    </DashboardLayout>
  );
}

export default StudyMaterial;

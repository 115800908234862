import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { Switch, Button, Modal, Box } from "@mui/material";
import { api } from "../../Api";
import Swal from "sweetalert2";

const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};
const handleToggle = async (id, newStatus, setData) => {
  try {
    // Show loading SweetAlert
    Swal.fire({
      title: "Updating Status",
      text: "Please wait...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    // Make the PUT request to update the status
    await axios.put(api + `/newskill/updateCompany?company_id=${id}`, {
      id,
      status: newStatus,
    });

    // Update the status in the local data state
    setData((prevData) =>
      prevData.map((row) => (row.id === id ? { ...row, status: newStatus } : row))
    );

    // Close the SweetAlert loading indicator and show success message
    Swal.fire({
      icon: "success",
      title: "Status Updated",
      text: "The status has been successfully updated.",
      timer: 1500,
      showConfirmButton: false,
    });
  } catch (error) {
    // Close the SweetAlert loading indicator and show error message
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "Failed to update the status. Please try again later.",
    });
    console.error("Error updating status:", error);
  }
};

const getCompanyDataApi = async () => {
  try {
    const res = await axios.get(api + "/newskill/getAllComp");
    if (res.data && Array.isArray(res.data.companies)) {
      const formattedData = res.data.companies.map((row) => ({
        id: row[0],
        username: row[1],
        contact_no: row[2],
        college_id: row[4],
        email: row[5],
        creationDate: formatDate(row[6]),
        package_name: row[7],
        status: row[3] || false,
      }));
      return formattedData;
    } else {
      console.error("Unexpected data structure", res.data);
      return [];
    }
  } catch (err) {
    console.error("Error fetching company data:", err);
    throw err;
  }
};

const CompanyList = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null); // Store API response data here
  const [loading, setLoading] = useState(false); // Track loading state

  useEffect(() => {
    getCompanyDataApi().then((formattedData) => setData(formattedData));
  }, []);

  // Fetch company details by email
  const handleOpen = async (email) => {
    try {
      setLoading(true);
      const res = await axios.get(`${api}/newskill/companyInfo?email=${email}`);
      if (res.data && res.data.company) {
        setSelectedCompany(res.data.company); // Correctly store company data in the state
      } else {
        console.error("Company not found:", res.data);
      }
    } catch (error) {
      console.error("Error fetching company details:", error);
    } finally {
      setLoading(false);
      setOpen(true); // Open the modal regardless of success or failure
    }
  };

  const handleClose = () => setOpen(false);

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "username", headerName: "Name", width: 200 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "contact_no", headerName: "Contact", width: 200 },
    { field: "package_name", headerName: "Package Name", width: 200 },
    { field: "college_id", headerName: "College ID", width: 200 },
    { field: "creationDate", headerName: "Creation Date", width: 200 },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (params) => (
        <Switch
          checked={params.value}
          onChange={(e) => handleToggle(params.row.id, e.target.checked, setData)}
        />
      ),
    },
    {
      field: "details",
      headerName: "Details",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color=""
          onClick={() => handleOpen(params.row.email)} // Pass the selected email to fetch company details
        >
          Show Details
        </Button>
      ),
    },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 25]}
        pagination
        getRowId={(row) => row.id}
      />

      {/* Modal to show details */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          {loading ? (
            <p>Loading...</p>
          ) : selectedCompany ? (
            <div>
              <h2>Company Details</h2>
              <hr />
              <p>
                <strong>Name:</strong> {selectedCompany.company_name}
              </p>
              <p>
                <strong>Registration No:</strong> {selectedCompany.registration_no}
              </p>
              <p>
                <strong>Contact 1:</strong> {selectedCompany.contact_1}
              </p>
              <p>
                <strong>Contact 2:</strong> {selectedCompany.contact_2}
              </p>
              <p>
                <strong>Website:</strong> {selectedCompany.web_url}
              </p>

              <p>
                <strong>About:</strong> {selectedCompany.about}
              </p>
              <p>
                <strong>Address Line 1:</strong> {selectedCompany.line_1}
              </p>
              <p>
                <strong>Address Line 2:</strong> {selectedCompany.line_2}
              </p>

              <p>
                <strong>City:</strong> {selectedCompany.city}
              </p>
              <p>
                <strong>State:</strong> {selectedCompany.state}
              </p>
              <p>
                <strong>Country:</strong> {selectedCompany.country}
              </p>
              <p>
                <strong>Pincode:</strong> {selectedCompany.pincode}
              </p>
              <p>
                <strong>Established Date:</strong> {selectedCompany.estd_date}
              </p>
            </div>
          ) : (
            <p>No details available</p>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default CompanyList;

import React, { useState, useEffect } from "react";
import { Container, TextField, Button, FormControl, Typography, Box, Modal } from "@mui/material";
import axios from "axios";
import { api } from "../../Api";
import { DataGrid } from "@mui/x-data-grid"; // Assuming you're using DataGrid from Material-UI
import Swal from "sweetalert2";

function TechnologyPage() {
  const [formData, setFormData] = useState({
    technology_name: "",
  });

  const [formErrors, setFormErrors] = useState({
    technology_name: "",
  });

  const [data, setData] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editTechData, setEditTechData] = useState(null);

  useEffect(() => {
    getTechnologyDataApi().then((formattedData) => setData(formattedData));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let errors = {};
    if (!formData.technology_name) errors.technology_name = "Technology Name is required";

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const response = await axios.post(api + "/newskill/createTech", formData);
        console.log("Form submitted successfully", response.data);
        getTechnologyDataApi().then((formattedData) => setData(formattedData));
        setFormData({ technology_name: "" }); // Clear form after submission
      } catch (error) {
        console.error("Error submitting form", error);
      }
    }
  };

  const getTechnologyDataApi = async () => {
    try {
      const res = await axios.get(api + "/newskill/getTech");
      const formattedData = res.data.technologies.map((row, index) => ({
        ...row,
        id: index + 1,
      }));
      return formattedData;
    } catch (err) {
      console.log("Error fetching technologies", err);
      throw err;
    }
  };

  const handleDelete = async (tech_id) => {
    try {
      await axios.delete(api + `/newskill/deleteTech?tech_id=${tech_id}`);
      getTechnologyDataApi().then((formattedData) => setData(formattedData));
      alert("Technology Deleted Successfully");
    } catch (err) {
      console.log("Error deleting technology:", err);
    }
  };

  const handleEdit = (tech_id) => {
    const techData = data.find((row) => row.tech_id === tech_id);
    setEditTechData(techData);
    setEditModalOpen(true);
  };

  const handleSaveEdit = async () => {
    try {
      await axios.put(api + `/newskill/editTech?tech_id=${editTechData?.tech_id}`, editTechData);
      setEditModalOpen(false);
      getTechnologyDataApi().then((formattedData) => setData(formattedData));

      const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });

      Toast.fire({
        icon: "warning",
        title: "Technology Updated Successfully.",
      });
    } catch (error) {
      console.error("Error updating technology", error);
    }
  };

  const handleCloseModal = () => {
    setEditModalOpen(false);
    setEditTechData(null);
  };

  const columns = [
    { field: "tech_id", headerName: "ID", width: 100 },
    { field: "technology_name", headerName: "Technology Name", width: 200 },
    {
      field: "deleteAction",
      headerName: "Delete",
      width: 150,
      renderCell: (params) => (
        <Button variant="contained" color="error" onClick={() => handleDelete(params.row.tech_id)}>
          Delete
        </Button>
      ),
    },
    {
      field: "editAction",
      headerName: "Edit",
      width: 150,
      renderCell: (params) => (
        <Button variant="contained" color="error" onClick={() => handleEdit(params.row.tech_id)}>
          Edit
        </Button>
      ),
    },
  ];

  return (
    <>
      <Container maxWidth="sm">
        <Box sx={{ mt: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Add Technology
          </Typography>
          <hr />
          <br />
          <form onSubmit={handleSubmit} noValidate>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <FormControl sx={{ minWidth: 200 }}>
                <TextField
                  label="Technology Name"
                  name="technology_name"
                  value={formData.technology_name}
                  onChange={handleChange}
                  error={Boolean(formErrors.technology_name)}
                  helperText={formErrors.technology_name}
                  fullWidth
                />
              </FormControl>

              <Button type="submit" variant="contained" color="error" sx={{ minWidth: 100 }}>
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Container>
      <br />
      <hr />
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 25]}
          pagination
          getRowId={(row) => row.id} // Specify the id property for each row
        />
      </div>
      <Modal
        open={editModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="edit-tech-modal-title"
        aria-describedby="edit-tech-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="edit-tech-modal-title" variant="h6" component="h2">
            Edit Technology
            {/* (ID: {editTechData?.tech_id})          show id on modal */}
          </Typography>
          <TextField
            margin="normal"
            fullWidth
            label="Technology Name"
            name="technology_name"
            value={editTechData?.technology_name || ""}
            onChange={(e) => setEditTechData({ ...editTechData, technology_name: e.target.value })}
          />
          <Button onClick={handleSaveEdit} variant="contained" color="error" sx={{ mr: 2 }}>
            Save
          </Button>
          <Button onClick={handleCloseModal} variant="contained" color="error">
            Cancel
          </Button>
        </Box>
      </Modal>
    </>
  );
}

export default TechnologyPage;

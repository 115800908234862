import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Button,
  TextField,
  Divider,
  CircularProgress,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import axios from "axios";
import { api } from "../../Api";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
function Payment() {
  const [students, setStudents] = useState([]);
  const [uid, setUid] = useState("");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    studentId: "",
    paid: "",
    mode: "",
    instId: "",
    date: "",
    batchName: "",
    balance: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [fees, setFees] = useState("");
  const [alreadyPaid, setAlreadyPaid] = useState(0); // Initialize to 0
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const resetForm = () => {
    setFormData({
      studentId: "",
      paid: "",
      mode: "",
      instId: "",
      date: "",
      batchName: "",
      balance: "",
    });
    setFees("");
    setAlreadyPaid(0); // Reset to 0
    setFormErrors({}); // Clear any previous errors
  };

  useEffect(() => {
    getOnlineTestDataApi();
  }, []);

  const getOnlineTestDataApi = async () => {
    try {
      const res = await axios.get(api + "/newskill/findStudents");
      setStudents(res.data.students || []);
    } catch (err) {
      console.error("Error fetching student data:", err);
    }
  };

  const getBatchAndFees = async () => {
    if (!uid) return;
    try {
      const res = await axios.get(api + `/newskill/getBatchAndFees?id=${uid}`);
      const paidAmount = res.data.tpaid || 0; // Set to 0 if null
      setFormData((prevData) => ({
        ...prevData,
        batchName: res.data.batch,
        balance: res.data.batchfees - paidAmount - (parseFloat(formData.paid) || 0), // Calculate balance
      }));
      setFees(res.data.batchfees);
      setAlreadyPaid(paidAmount); // Update alreadyPaid state
    } catch (err) {
      console.error("Error fetching batch and fees:", err);
    }
  };

  useEffect(() => {
    getBatchAndFees();
    getPaymentHistory();
  }, [uid]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });

    if (name === "studentId") {
      setUid(value);
    }
    if (name === "paid") {
      const enteredAmount = parseFloat(value) || 0;
      const feeAmount = parseFloat(fees) || 0;
      const currentBalance = feeAmount - alreadyPaid - enteredAmount; // Update balance calculation
      setFormData((prevData) => ({
        ...prevData,
        balance: currentBalance, // Update balance in formData
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = {};

    // Validate required fields
    for (const key in formData) {
      if (!formData[key]) {
        errors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required.`;
      }
    }

    // Set errors if any
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    // API call to save form data
    try {
      const response = await axios.post(api + "/newskill/savePaid", formData);
      alert(response.data);
      resetForm();
      getPaymentHistory();
    } catch (error) {
      console.error("Error saving payment:", error);
      alert("Failed to save payment.");
    }
  };
  const getPaymentHistory = async () => {
    setLoading(true); // Start loading
    try {
      const res = await axios.get(api + "/newskill/getAllPaidHistory");
      console.log("Fetched payment history:", res.data);
      console.log(data); // Check if data is correct

      // Map through the installments to set formatted data
      const formattedData = res.data.data.map((inst) => ({
        id: inst.id,
        studentId: inst.studentId || "N/A",
        studentName: inst.studentName || "N/A",
        batchName: inst.batchName || "N/A", // Use batchName instead of batchId
        instId: inst.instId || "N/A", // Installment ID
        paid: inst.paid || 0, // Amount paid
        rid: inst.rid || "N/A",
        mode: inst.mode || "N/A", // Payment mode (cash, etc.)
        // batchFees: inst.batchFees || 0, // Total fees for the batch
        balance: inst.balance || 0, // Remaining balance
      }));

      setData(formattedData); // Set formatted data to state
    } catch (err) {
      console.error("Error fetching payment history:", err);
    } finally {
      setLoading(false); // Stop loading regardless of success or error
    }
  };
  const handleToggledownloadModal = (rid, studentName, studentId) => {
    const rowData = data.find(
      (row) => row.id === rid && row.studentName === studentName && row.studentId === studentId
    );
    navigate(`/invoice/${rid}/${studentName}/${studentId}`); // Make sure studentId is included
  };

  const columns = [
    { field: "id", headerName: "ID", width: 20 },
    { field: "studentId", headerName: "S-Id", width: 100 },
    { field: "studentName", headerName: "Student Name", width: 200 },
    { field: "batchName", headerName: "Batch", width: 200 },
    { field: "instId", headerName: "Installment", width: 150 },
    { field: "paid", headerName: "Paid Amount", width: 150 },
    { field: "rid", headerName: "Receipt Id", width: 150 },
    { field: "mode", headerName: "Payment Mode", width: 150 },
    { field: "balance", headerName: "Balance Remaining", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 350,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="contained"
            color="success"
            onClick={() =>
              handleToggledownloadModal(
                params.row.rid,
                params.row.studentName,
                params.row.studentId
              )
            } // Pass studentId
            style={{ marginRight: 8 }}
          >
            View Receipt
          </Button>
        </div>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Container maxWidth="sm">
          <Box sx={{ mt: 4 }}>
            <Typography variant="h4" component="h1" gutterBottom>
              Payment Section
            </Typography>
            <hr />
            <br />
            <form onSubmit={handleSubmit} noValidate>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <FormControl sx={{ minWidth: 200 }}>
                  <FormLabel component="legend">Select Student</FormLabel>
                  <Select
                    name="studentId"
                    value={formData.studentId}
                    onChange={handleChange}
                    error={Boolean(formErrors.studentId)}
                    displayEmpty
                    fullWidth
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    {students.map((student) => (
                      <MenuItem key={student[0]} value={student[0]}>
                        {student[1]}
                      </MenuItem>
                    ))}
                  </Select>
                  {formErrors.studentId && (
                    <Typography color="error">{formErrors.studentId}</Typography>
                  )}
                </FormControl>
                <FormControl sx={{ minWidth: 200 }}>
                  <FormLabel component="legend">Batch Name</FormLabel>
                  <TextField
                    type="text"
                    name="batchName"
                    value={formData.batchName}
                    readOnly
                    fullWidth
                  />
                </FormControl>
                <FormControl sx={{ minWidth: 200 }}>
                  <FormLabel component="legend">Batch Fees</FormLabel>
                  <TextField type="number" value={fees} readOnly fullWidth />
                </FormControl>
                <FormControl sx={{ minWidth: 200 }}>
                  <FormLabel component="legend">Already Paid</FormLabel>
                  <TextField type="number" value={alreadyPaid} readOnly fullWidth />
                </FormControl>
                <FormControl sx={{ minWidth: 200 }}>
                  <FormLabel component="legend">Select Installment</FormLabel>
                  <Select
                    name="instId"
                    value={formData.instId}
                    onChange={handleChange}
                    error={Boolean(formErrors.instId)}
                    displayEmpty
                    fullWidth
                  >
                    <MenuItem value="" disabled>
                      Select Installment
                    </MenuItem>
                    <MenuItem value="Installment 1st">Installment 1st</MenuItem>
                    <MenuItem value="Installment 2nd">Installment 2nd</MenuItem>
                    <MenuItem value="Installment 3rd">Installment 3rd</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ minWidth: 200 }}>
                  <FormLabel component="legend">Enter Paying Amount</FormLabel>
                  <TextField
                    label="Amount"
                    type="number"
                    name="paid"
                    value={formData.paid}
                    onChange={handleChange}
                    error={Boolean(formErrors.paid)}
                    helperText={formErrors.paid}
                    fullWidth
                  />
                </FormControl>
                <FormControl sx={{ minWidth: 200 }}>
                  <FormLabel component="legend">Payment Mode</FormLabel>
                  <TextField
                    label="Cash / Online"
                    name="mode"
                    value={formData.mode}
                    onChange={handleChange}
                    error={Boolean(formErrors.mode)}
                    helperText={formErrors.mode}
                    fullWidth
                  />
                </FormControl>
                <FormControl sx={{ minWidth: 200 }}>
                  <FormLabel component="legend">Balance Amount</FormLabel>
                  <TextField
                    label="Amount"
                    type="number"
                    name="balance"
                    value={formData.balance}
                    readOnly
                    fullWidth
                  />
                </FormControl>
                <FormControl sx={{ minWidth: 200 }}>
                  <FormLabel component="legend">Payment Date</FormLabel>
                  <TextField
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                    error={Boolean(formErrors.date)}
                    helperText={formErrors.date}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
                <Button type="submit" variant="contained">
                  Submit
                </Button>
              </Box>
            </form>
          </Box>
        </Container>
        <Divider />
        <Box sx={{ marginBottom: "42px" }}>
          <div style={{ height: 400, width: "100%" }}>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <DataGrid
                rows={data}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 25]}
                pagination
                getRowId={(row) => row.id}
              />
            )}
          </div>
        </Box>
      </MDBox>
    </DashboardLayout>
  );
}

export default Payment;

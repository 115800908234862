import { Box, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "layouts/authentication/components/Footer";
import React from "react";
import { Link, Route } from "react-router-dom";
import AddUsers from "./AddUsers";

function TrainingDashboard() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Box pt={6} pb={3} display="grid" gap={2} gridTemplateColumns="repeat(4, 1fr)">
          {/* First row - 4 items */}

          <Box bgcolor="primary.main" p={2} borderRadius={2}>
            <Link to="/AddUsers" style={{ textDecoration: "none", color: "inherit" }}>
              <h3>Add Users</h3>
            </Link>
          </Box>

          <Box bgcolor="secondary.main" p={2} borderRadius={2}>
            <Link to="/CreateBatch" style={{ textDecoration: "none", color: "inherit" }}>
              <h3>Create Batch</h3>
            </Link>
          </Box>
          <Box bgcolor="success.main" p={2} borderRadius={2}>
            <Link to="/TraineeList" style={{ textDecoration: "none", color: "inherit" }}>
              <h3> Trainee Student List</h3>
            </Link>
          </Box>
          <Box bgcolor="info.main" p={2} borderRadius={2}>
            <Link to="/TraineeTutorList" style={{ textDecoration: "none", color: "inherit" }}>
              <h3> Trainee Tutor List</h3>
            </Link>
          </Box>
          <Box bgcolor="error.main" p={2} borderRadius={2}>
            <Link to="/AssignBatch" style={{ textDecoration: "none", color: "inherit" }}>
              <h3> Assign Batch</h3>
            </Link>
          </Box>
          <Box bgcolor="primary.main" p={2} borderRadius={2}>
            <Link to="/Payment" style={{ textDecoration: "none", color: "inherit" }}>
              <h3> Payment</h3>
            </Link>
          </Box>
          <Box bgcolor="secondary.main" p={2} borderRadius={2}>
            <Link to="/StudyMaterial" style={{ textDecoration: "none", color: "inherit" }}>
              <h3> Study Material</h3>
            </Link>
          </Box>
          <Box bgcolor="success.main" p={2} borderRadius={2}>
            <Link to="/DeactivateUsers" style={{ textDecoration: "none", color: "inherit" }}>
              <h3> Deactivate Users </h3>
            </Link>
          </Box>
          <Box bgcolor="error.main" p={2} borderRadius={2}>
            Item 4
          </Box>

          {/* Second row - 2 items */}
          <Box bgcolor="warning.main" p={2} borderRadius={2} gridColumn="span 2">
            Item 5
          </Box>
          <Box bgcolor="info.main" p={2} borderRadius={2} gridColumn="span 2">
            Item 6
          </Box>
          {/* Second row - 2 items */}
          <Box bgcolor="warning.main" p={2} borderRadius={2} gridColumn="span 2">
            Item 5
          </Box>
          <Box bgcolor="info.main" p={2} borderRadius={2} gridColumn="span 2">
            Item 6
          </Box>
          {/* Second row - 2 items */}
          <Box bgcolor="warning.main" p={2} borderRadius={2} gridColumn="span 2">
            Item 5
          </Box>
          <Box bgcolor="info.main" p={2} borderRadius={2} gridColumn="span 2">
            Item 6
          </Box>
          {/* Second row - 2 items */}
          <Box bgcolor="warning.main" p={2} borderRadius={2} gridColumn="span 2">
            Item 5
          </Box>
          <Box bgcolor="info.main" p={2} borderRadius={2} gridColumn="span 2">
            Item 6
          </Box>
        </Box>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default TrainingDashboard;

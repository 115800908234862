import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { Button, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import EditQue from "components/Modal/EditQue";
import { api } from "../../Api";
import Swal from "sweetalert2";

const Reports = () => {
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [collegeFilter, setCollegeFilter] = useState("");
  const [roleFilter, setRoleFilter] = useState(""); // Default role filter: None

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editData, setEditData] = useState(null);

  useEffect(() => {
    fetchStudents();
  }, [roleFilter, collegeFilter]); // Fetch students whenever roleFilter or collegeFilter changes

  const fetchStudents = () => {
    if (roleFilter === "0" && collegeFilter === "") {
      return;
    }

    const role = roleFilter === "0" ? "0" : "10"; // Map role filter to appropriate value
    const collegeId = collegeFilter === "" ? "0" : collegeFilter; // Use "0" for "All" option
    getStudentsApi(role, collegeId)
      .then((studentsData) => {
        setStudents(studentsData);
        setFilteredStudents(studentsData);
      })
      .catch((error) => console.error("Error fetching students:", error));
  };

  const getStudentsApi = async (role, collegeId) => {
    try {
      const res = await axios.get(
        `${api}/newskill/getStudents?role=${role}&college_id=${collegeId}`
      );
      return res.data.students.map((student, index) => ({
        id: index + 1,
        name: student[0],
        email: student[1],
        phone: student[2],
        collegeId: student[4], // Assuming collegeId is retrieved from API response
      }));
    } catch (error) {
      console.error("Error fetching students:", error);
      throw error;
    }
  };

  const handleRoleFilterChange = (event) => {
    const value = event.target.value;
    setRoleFilter(value);
    if (value === "0") {
      // If Internal Student is selected, ensure collegeFilter is not empty
      if (collegeFilter === "") {
        const Toast = Swal.mixin({
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });

        Toast.fire({
          icon: "warning",
          title: "Please select a college for Internal Students.",
        });
      }
    }
    // Fetch students when role filter changes
    fetchStudents();
  };

  const handleCollegeFilterChange = (event) => {
    const value = event.target.value;
    setCollegeFilter(value);
    fetchStudents(); // Fetch students when college filter changes
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", width: 200 },
    { field: "email", headerName: "Email", width: 250 },
    { field: "phone", headerName: "Phone", width: 150 },
  ];

  return (
    <>
      <div>
        <FormControl
          style={{ marginBottom: 16, marginRight: 10, minWidth: 120, width: "20%", color: "blue" }}
        >
          <InputLabel sx={{ color: "blue" }}>Select Role</InputLabel>
          <Select
            value={roleFilter}
            onChange={handleRoleFilterChange}
            sx={{ color: "blue", height: "44px" }}
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            <MenuItem value="0">Internal Student</MenuItem>
            <MenuItem value="10">External Student</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ marginBottom: 16, minWidth: 120, width: "20%", color: "blue" }}>
          <InputLabel sx={{ color: "blue" }}>Select College</InputLabel>
          <Select
            value={collegeFilter}
            onChange={handleCollegeFilterChange}
            disabled={roleFilter !== "0"}
            sx={{ color: "blue", height: "44px" }}
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            <MenuItem value="1">RCERT</MenuItem>
            <MenuItem value="3">Vmv College</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={filteredStudents}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 25]}
          pagination
          getRowId={(row) => row.id}
        />

        {isEditModalOpen && editData && (
          <EditQue
            editData={editData}
            handleClose={() => setIsEditModalOpen(false)}
            fetchStudents={fetchStudents} // Pass fetchStudents function to refresh data after edit
          />
        )}
      </div>
    </>
  );
};

export default Reports;

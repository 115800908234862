import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { Button, MenuItem, Select, FormControl, InputLabel, CircularProgress } from "@mui/material";
import { api } from "../../Api";
import EditQue from "components/Modal/EditQue";
const getOnlineTestDataApi = async () => {
  try {
    const res = await axios.get(api + "/newskill/getQuestions");

    // Flatten the questions and filter out strings
    const flattenedData = res.data.Questions.flat().filter((row) => typeof row === "object");

    const formattedData = flattenedData.map((row, index) => ({
      ...row,
      id: row.id || index, // Ensure each row has a unique id
    }));

    return formattedData;
  } catch (err) {
    console.error("Error fetching data:", err);
    throw err;
  }
};

const QuestionList2 = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [difficultyFilter, setDifficultyFilter] = useState("");

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editData, setEditData] = useState(null);

  useEffect(() => {
    TestList();
  }, []);

  const TestList = () => {
    getOnlineTestDataApi()
      .then((formattedData) => {
        setData(formattedData);
        setFilteredData(formattedData);
        console.log("Formatted data:", formattedData);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const handleToggleEditModal = (id) => {
    const rowData = data.find((row) => row.id === id);
    setEditData(rowData);
    setIsEditModalOpen(!isEditModalOpen);
  };

  const handleFilterChange = (event) => {
    const value = event.target.value;
    setFilterValue(value);
    applyFilters(value, difficultyFilter);
  };

  const handleDifficultyFilterChange = (event) => {
    const value = event.target.value;
    setDifficultyFilter(value);
    applyFilters(filterValue, value);
  };

  const applyFilters = (correctAnswer, difficulty) => {
    let filtered = data;
    if (correctAnswer !== "") {
      filtered = filtered.filter((row) => row.correctAnswer === correctAnswer);
    }
    if (difficulty !== "") {
      filtered = filtered.filter((row) => row.difficultyLevel === difficulty);
    }
    setFilteredData(filtered);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "text", headerName: "Name", width: 200 },
    { field: "option1", headerName: "Option 1", width: 100 },
    { field: "option2", headerName: "Option 2", width: 100 },
    { field: "option3", headerName: "Option 3", width: 100 },
    { field: "option4", headerName: "Option 4", width: 100 },
    { field: "correctAnswer", headerName: "Answer", width: 100 },
    { field: "difficultyLevel", headerName: "Difficulty", width: 100 },
    { field: "marksques", headerName: "Marks", width: 100 },
    { field: "type", headerName: "Type", width: 100 },
    { field: "examId", headerName: "Exam ID", width: 100 },
    {
      field: "actions",
      headerName: "Actions",
      width: 350,
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            color="success"
            onClick={() => handleToggleEditModal(params.row.id)}
            style={{ marginRight: 8 }}
          >
            Edit
          </Button>
          {/* <Button
            variant="contained"
            color="error"
            onClick={() => handleDelete(params.row.id)}
            style={{ marginRight: 8 }}
          >
            Delete
          </Button> */}
        </>
      ),
    },
  ];

  return (
    <>
      {/* <FormControl style={{ marginBottom: 16, minWidth: 120, width: "10%" }}>
        <InputLabel>Filter by Answer</InputLabel>
        <Select value={filterValue} onChange={handleFilterChange}>
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          {[...new Set(data.map((item) => item.correctAnswer))].map((answer, index) => (
            <MenuItem key={index} value={answer}>
              {answer}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}
      <FormControl
        sx={{ marginBottom: 2, minWidth: 120, width: "20%", marginLeft: 2, color: "blue" }}
      >
        <InputLabel sx={{ color: "blue" }}>Filter by Difficulty</InputLabel>
        <Select
          value={difficultyFilter}
          onChange={handleDifficultyFilterChange}
          sx={{ color: "blue", height: "44px" }}
        >
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          {[...new Set(data.map((item) => item.difficultyLevel))].map((difficulty, index) => (
            <MenuItem key={index} value={difficulty}>
              {difficulty}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div style={{ height: 400, width: "100%" }}>
        {filteredData.length > 0 ? (
          <DataGrid
            rows={filteredData}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 25]}
            pagination
            getRowId={(row) => row.id}
          />
        ) : (
          <div style={{ display: "block", position: "relative", left: "50%", top: "50%" }}>
            <CircularProgress />
          </div>
        )}

        {isEditModalOpen && editData && (
          <EditQue
            editData={editData}
            handleClose={() => setIsEditModalOpen(false)}
            getOnlineTestDataApi={TestList}
          />
        )}
      </div>
    </>
  );
};

export default QuestionList2;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#invoice {
  /* background-image: url("assets/images/bg.png"); */
  /* /* background-size: cover; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

}
`, "",{"version":3,"sources":["webpack://./src/components/TrainingPage/payment.css"],"names":[],"mappings":"AAAA;EACE,mDAAmD;EACnD,+BAA+B;EAC/B,2BAA2B;EAC3B,4BAA4B;EAC5B,wBAAwB;;AAE1B","sourcesContent":["#invoice {\n  /* background-image: url(\"assets/images/bg.png\"); */\n  /* /* background-size: cover; */\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: contain;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { CircularProgress, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "layouts/authentication/components/Footer";
import React, { useEffect, useState } from "react";
import axios from "axios";
import * as XLSX from "xlsx"; // Import the xlsx library
import { api } from "../../Api";

function CampusDriveList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const formattedData = await getOnlineTestDataApi();
        setData(formattedData);
      } catch (err) {
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const getOnlineTestDataApi = async () => {
    try {
      const res = await axios.get(api + "/newskill/passedStudents");
      console.log("Fetched data:", res.data);

      // Check if the response has data and map it correctly
      if (res.data && res.data.data) {
        const formattedData = res.data.data.map((student, index) => ({
          id: index + 1, // Assigning an id based on the index
          name: student[0], // Name
          status: student[1], // Status (Pass/Fail)
          marks: student[2], // Marks
          cutoff: student[3] || 20, // Cutoff
        }));

        return formattedData;
      } else {
        return []; // Return empty if no data
      }
    } catch (err) {
      console.error("Error fetching data:", err);
      throw err;
    }
  };

  const downloadExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, "Campus Drive Data");
    XLSX.writeFile(wb, "CampusDriveData.xlsx");
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", width: 200 },
    { field: "cutoff", headerName: "Cut Off", width: 100 },
    { field: "marks", headerName: "Score", width: 100 },
    { field: "status", headerName: "Status", width: 100 },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div style={{ height: 400, width: "100%" }}>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Button
              variant="contained"
              color=""
              onClick={downloadExcel}
              style={{ marginBottom: "10px" }}
            >
              Download Excel
            </Button>
            <DataGrid
              rows={data}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5, 10, 25]}
              pagination
              getRowId={(row) => row.id} // Using id as the row ID
            />
          </>
        )}
      </div>
    </DashboardLayout>
  );
}

export default CampusDriveList;

import { Button, CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "layouts/authentication/components/Footer";
import React, { useEffect, useState } from "react";
import axios from "axios"; // Ensure axios is imported
import { api } from "../../Api";
function DeactivateUser() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const formattedData = await getOnlineTestDataApi();
        setData(formattedData);
      } catch (err) {
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleActivate = async (id, role) => {
    if (role == "TUTOR") {
      try {
        await axios.put(api + `/newskill/deactivateTutor?tutorId=${id}`);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Tutor De-Activated  Successfully.",
        });
        const updatedData = data.filter((row) => row.id !== id);
        setData(updatedData);
      } catch (error) {
        console.error("Error deleting test:", error);
      }
    } else {
      try {
        await axios.put(api + `/newskill/deactivate?userId=${id}`);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "User De-Activated  Successfully.",
        });
        const updatedData = data.filter((row) => row.id !== id);
        setData(updatedData);
      } catch (error) {
        console.error("Error deleting test:", error);
      }
    }
  };

  const getOnlineTestDataApi = async () => {
    try {
      const res = await axios.get(api + "/newskill/getbyStatus");
      console.log("Fetched data:", res.data);

      const formattedData = res.data.map((student, index) => ({
        id: student.userId || `temp-id-${index}`, // Fallback id if userId is missing
        name: student.name,
        email: student.email,
        mobNo: student.mobNo,
        designation: student.designation || student.batchname,
        role: student.role,
        createdDate: student.createdDate,
        gender: student.gender || "N/A",
        dob: student.dob ? new Date(student.dob).toLocaleDateString("en-US") : "N/A",
        address: student.address || "N/A",
        enabled: student.enabled,
      }));

      return formattedData;
    } catch (err) {
      console.error("Error fetching data:", err);
      throw err;
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", width: 100 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "dob", headerName: "DOB", width: 120 },
    { field: "gender", headerName: "Gender", width: 70 },
    { field: "mobNo", headerName: "Mobile", width: 150 },
    { field: "role", headerName: "Role", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="error"
          onClick={() => handleActivate(params.row.id, params.row.role)}
        >
          Activate
        </Button>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div style={{ height: 400, width: "100%" }}>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <DataGrid
            rows={data}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 25]}
            pagination
            getRowId={(row) => row.id}
          />
        )}
      </div>
    </DashboardLayout>
  );
}

export default DeactivateUser;

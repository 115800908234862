import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormLabel,
  TextField,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "layouts/authentication/components/Footer";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import AddQue from "components/Modal/AddQue";
import EditTest from "components/Modal/EditTest";
import { api } from "../../Api";
import Swal from "sweetalert2";
function CreateBatch() {
  // State for form data
  const [formData, setFormData] = useState({
    batchName: "",
    duration: "",
    batchStartdate: "",
    batchEnddate: "",
  });

  // State for form errors
  const [formErrors, setFormErrors] = useState({
    batchName: "",
    duration: "",
    batchStartdate: "",
    batchEnddate: "",
  });

  // State for data, modal visibility, and selected row
  const [data, setData] = useState([]); // Initialize data state to an empty array
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [editData, setEditData] = useState(null);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(api + "/newskill/batch", formData);
      Swal.fire({ icon: "success", title: "Success!", text: "Form submitted successfully." });
      TestList();
      // Handle success, e.g., reset form or show a success message
    } catch (error) {
      console.error("There was an error submitting the form", error);
      // Handle error, e.g., show an error message
    }
  };

  // Fetch test data from API
  const getOnlineTestDataApi = async () => {
    try {
      const res = await axios.get(api + "/newskill/allBatches");
      console.log("Fetched data:", res.data);
      const formattedData = res.data.students.map((row, index) => ({
        id: row.batchId, // Use batchId as the unique ID, or generate one if batchId is not unique
        batchId: row.batchId,
        batchName: row.batchName,
        creationDate: formatDate(row.crationDate),
        expirationDate: row.batchEnddate ? formatDate(row.batchEnddate) : "N/A",
        duration: row.duration,
        batchStartDate: formatDate(row.batchStartdate),
        traineeAssignments: row.traineeAssignments,
      }));
      return formattedData;
    } catch (err) {
      console.error("Error fetching data:", err);
      throw err;
    }
  };

  // Load test data
  const TestList = () => {
    getOnlineTestDataApi()
      .then((formattedData) => {
        setData(formattedData); // Correctly set the data state
        console.log("Formatted data:", formattedData);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  // Format date function
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  // Handle save after editing
  const handleSave = () => {
    // Implement save logic here
    TestList(); // Refresh data after saving
  };

  useEffect(() => {
    TestList();
  }, []);

  const columns = [
    { field: "batchId", headerName: "Batch ID", width: 150 },
    { field: "batchName", headerName: "Batch Name", width: 200 },
    { field: "creationDate", headerName: "Creation Date", width: 150 },
    { field: "expirationDate", headerName: "Expiration Date", width: 150 },
    { field: "duration", headerName: "Duration (Months)", width: 150 },
    { field: "batchStartDate", headerName: "Batch Start Date", width: 150 },
    // Add more columns as needed
  ];

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Container maxWidth="sm">
            <Box sx={{ mt: 4 }}>
              <Typography variant="h4" component="h1" gutterBottom>
                Create Batch
              </Typography>
              <hr />
              <br />
              <form onSubmit={handleSubmit} noValidate>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <FormControl sx={{ minWidth: 200 }}>
                    <FormLabel component="legend">Batch Name</FormLabel>
                    <TextField
                      label="Batch Name"
                      name="batchName"
                      value={formData.batchName}
                      onChange={handleChange}
                      error={Boolean(formErrors.batchName)}
                      helperText={formErrors.batchName}
                      fullWidth
                    />
                  </FormControl>
                  <FormControl sx={{ minWidth: 200 }}>
                    <FormLabel component="legend">Duration</FormLabel>
                    <TextField
                      label="Duration"
                      name="duration"
                      value={formData.duration}
                      onChange={handleChange}
                      error={Boolean(formErrors.duration)}
                      helperText={formErrors.duration}
                      fullWidth
                    />
                  </FormControl>
                  <FormControl sx={{ minWidth: 200 }}>
                    <FormLabel component="legend">Start Date</FormLabel>
                    <TextField
                      label="Start Date"
                      type="date"
                      name="batchStartdate"
                      value={formData.batchStartdate}
                      onChange={handleChange}
                      error={Boolean(formErrors.batchStartdate)}
                      helperText={formErrors.batchStartdate}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </FormControl>
                  <FormControl sx={{ minWidth: 200 }}>
                    <FormLabel component="legend">End Date</FormLabel>
                    <TextField
                      label="End Date"
                      type="date"
                      name="batchEnddate"
                      value={formData.batchEnddate}
                      onChange={handleChange}
                      error={Boolean(formErrors.batchEnddate)}
                      helperText={formErrors.batchEnddate}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </FormControl>
                  <Button type="submit" variant="contained" color="success" sx={{ minWidth: 100 }}>
                    Submit
                  </Button>
                </Box>
              </form>
            </Box>
          </Container>
        </MDBox>
        <div style={{ height: 400, width: "100%" }}>
          {data.length > 0 ? (
            <DataGrid
              rows={data}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5, 10, 25]}
              pagination
              getRowId={(row) => row.id}
            />
          ) : (
            <div style={{ display: "block", position: "relative", left: "50%", top: "50%" }}>
              <CircularProgress />
            </div>
          )}

          {isAddModalOpen && (
            <AddQue selectedRowId={selectedRowId} handleClose={() => setIsAddModalOpen(false)} />
          )}

          {isEditModalOpen && editData && (
            <EditTest
              editData={editData}
              handleClose={() => setIsEditModalOpen(false)}
              handleSave={handleSave}
              getOnlineTestDataApi={TestList}
            />
          )}
        </div>

        <Footer />
      </DashboardLayout>
    </div>
  );
}

export default CreateBatch;
